import React from "react"
import styled from "@emotion/styled"
import { FieldLabel } from "../fields"
import CalculateTextBox from "../CalculateTextBox/CalculateTextBox"
import { fieldNames } from "../../../../utils/enums"

const RegistrationNumber = () => {
  return (
    <FieldContainer>
      <FieldLabel labelFor={fieldNames.REG_NR} title="Regnummer" descText="" />

      <CalculateTextBox
        fieldName={fieldNames.REG_NR}
        placeHolder="ABC123"
        fieldType="text"
        maxLength="10"
      />
    </FieldContainer>
  )
}
export default RegistrationNumber

const FieldContainer = styled.div`
  width: 100%;
  max-width: 224px;
  margin-right: 13px;

  @media (max-width: 414px) {
    margin-right: 15px;
    max-width: 145px;
  }

  @media (max-width: 375px) {
    margin-right: 15px;
  }

  @media (max-width: 374px) {
    margin-right: 10px;
  }
`
