import styled from "@emotion/styled"

const FieldSet = styled.fieldset`
  ${props => (props.hasMargin ? props.hasMargin : "")};
  border: 0;
  width: 100%;
  margin-right: 1rem;
`
export default FieldSet

export const FieldContainer = styled.div`
  width: 100%;
`
