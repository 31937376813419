import React, { useContext } from "react"
import styled from "@emotion/styled"
import { QuoteContext } from "../../../Context/QuoteContext"
import {
  SocialSecurity,
  RegistrationNumber,
  CalculateButton,
  FormMessage,
} from "../Fields/fields"
import { Link, navigate } from "gatsby"
import {
  updateQuote,
  fetchingFailed,
  updateField,
  initFetchQuote,
} from "../../../Context/Actions"
import { DispatchRequest } from "../../../API/QuoteService"
import { fieldValidation } from "../Fields/FieldValidation"
import { ENDPOINT } from "../../../API/endpoints"
import { fieldNames } from "../../../utils/enums"
import { CleanSSN, removeSpaces } from "../../../utils/HelperFunctions"

const Calculate = () => {
  const { state, dispatch } = useContext(QuoteContext)

  const { fields, error } = state

  const handleValidation = fieldNames => {
    fieldNames.forEach(fieldName => {
      const validated = fieldValidation(
        fieldName,
        fields[fieldName].fieldValue.trim().replace(/\s/g, "")
      )

      dispatch(
        updateField({
          name: fieldName,
          value: fields[fieldName].fieldValue,
          valid: validated.valid,
          message: validated.message,
          dirty: true,
        })
      )
    })

    if (fields.registrationNumber.valid && fields.socialSecurity.valid) {
      return true
    }

    return false
  }

  const onSubmit = event => {
    event.preventDefault()

    if (handleValidation([fieldNames.REG_NR, fieldNames.SSN])) {
      dispatch(initFetchQuote(true))

      DispatchRequest(ENDPOINT.CALCULATE, {
        agreementId: 1,
        inputData: {
          [fieldNames.SSN]: CleanSSN(state.fields[fieldNames.SSN].fieldValue),
          [fieldNames.REG_NR]: removeSpaces(
            state.fields[fieldNames.REG_NR].fieldValue
          ),
          formatType: "list",
        },
      }).then(response => {
        if (response.status === 200) {
          if (response.data.status.code === 0) {
            // All is good
            dispatch(updateQuote(response.data))
            navigate("/bilforsakring/offert")
          } else {
            // Something is wrong
            dispatch(
              fetchingFailed(
                response.data.status.message ||
                  "Vi har tekniska problem, prova igen om en stund."
              )
            )
          }
        } else {
          // Something is very wrong
          dispatch(fetchingFailed("Systemet svarar inte."))
        }
      })
    }
  }

  return (
    <>
      <CalculateForm onSubmit={onSubmit} noValidate>
        <Fieldset disabled={state.initFetchQuote}>
          <FormBody initFetchQuote={state.initFetchQuote}>
            <RegistrationNumber />

            <SocialSecurity />
          </FormBody>

          <FormFooter>
            <Policy>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="14"
                viewBox="0 0 10 14"
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    fill="#5558EA"
                    fillRule="nonzero"
                    d="M6.199 8.76c0 .539-.44.98-.979.98a.981.981 0 0 1-.978-.98c0-.537.44-.977.978-.977.539 0 .979.44.979.978z"
                  />
                  <path
                    stroke="#5558EA"
                    strokeWidth="1.5"
                    d="M8.032 5.558V4.405a2.855 2.855 0 0 0-2.884-2.883 2.855 2.855 0 0 0-2.883 2.883v1.153"
                  />
                  <path
                    stroke="#5558EA"
                    strokeWidth="1.5"
                    d="M8.608 12.355h-6.92a.577.577 0 0 1-.576-.577V6.011c0-.318.258-.576.576-.576h6.92c.319 0 .577.258.577.576v5.767a.577.577 0 0 1-.577.577z"
                  />
                </g>
              </svg>
              Dina uppgifter hanteras enligt vår{" "}
              <Link to="/personuppgifter">personuppgiftspolicy</Link>.
            </Policy>
            <div>
              <CalculateButton isLoading={state.initFetchQuote} />
              <FormMessage message={error} />
            </div>
          </FormFooter>
        </Fieldset>
      </CalculateForm>
    </>
  )
}
export default Calculate

const CalculateForm = styled.form`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Fieldset = styled.fieldset`
  margin: 0;
  border: 0;
  box-shadow: 0 2px 16px 5px rgba(0, 0, 0, 0.07);
  padding: 30px 26px 26px 26px;
  width: 100%;
  max-width: 515px;
  background: #ffffff;

  @media (max-width: 375px) {
    padding: 25px 10px;
    width: 100%;
  }

  @media (max-width: 320px) {
    padding: 15px;
    width: 100%;
  }
`

const FormBody = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  opacity: ${props => (props.initFetchQuote ? "0.4" : "1")};

  @media (max-width: 374px) {
    /* flex-direction: column; */
  }
`

const Policy = styled.p`
  margin: 17px 0 25px 0;
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 15px;
  letter-spacing: normal;
  color: #71718e;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin: 0;
    margin-right: 5px;
  }

  a {
    color: #71718e;
    text-decoration: underline;
    display: contents;
  }

  @media (max-width: 375px) {
    margin: 0;
    margin-top: 15px;
    justify-content: center;
  }
`

const FormFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  @media (max-width: 375px) {
    flex-direction: column-reverse;
  }
`
