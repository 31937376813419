export function validate(fieldValue) {
  if (!fieldValue) {
    return {
      valid: false,
      message: "Fyll i regnummer",
    }
  }

  if (fieldValue.length < 6) {
    return {
      valid: false,
      message: "Felaktigt regnummer",
    }
  }
  if (fieldValue.length > 6) {
    return {
      valid: false,
      message: "Felaktigt regnummer",
    }
  }

  if (disallowedCombinations.includes(fieldValue.slice(0, 3).toUpperCase())) {
    return {
      valid: false,
      message: "Ogiltigt regnummer",
    }
  }

  const chars = fieldValue
    .replace(/[0-9]/g, "")
    .toUpperCase()
    .split("")

  const isDissallowed = chars.some(item => {
    return disallowedChars.includes(item)
  })

  if (isDissallowed) {
    return {
      valid: false,
      message: "Ogiltigt regnummer",
    }
  }

  if (!fieldValue.match(/^[A-Za-z]{3}[ ]{0,1}\d{2}[A-Za-z\d]$/)) {
    return {
      valid: false,
      message: "Ogiltigt regnummer",
    }
  }

  return {
    valid: true,
    message: "",
  }
}

const disallowedChars = ["I", "Q", "V", "Å", "Ä", "Ö"]

const disallowedCombinations = [
  "APA",
  "ARG",
  "ASS",
  "BAJ",
  "BSS",
  "CUC",
  "CUK",
  "DUM",
  "ETA",
  "ETT",
  "FAG",
  "FAN",
  "FEG",
  "FEL",
  "FEM",
  "FES",
  "FET",
  "FNL",
  "FUC",
  "FUK",
  "FUL",
  "GAM",
  "GAY",
  "GEJ",
  "GEY",
  "GHB",
  "GUD",
  "GYN",
  "HAT",
  "HBT",
  "HKH",
  "HOR",
  "HOT",
  "KGB",
  "KUC",
  "KUF",
  "KUG",
  "KUK",
  "KYK",
  "LAM",
  "LAT",
  "LEM",
  "LOJ",
  "LSD",
  "LUS",
  "MAD",
  "MAO",
  "MEN",
  "MES",
  "MLB",
  "MUS",
  "NAZ",
  "NRP",
  "NYP",
  "OND",
  "OOO",
  "ORM",
  "PAJ",
  "PKK",
  "PLO",
  "PMS",
  "PUB",
  "RAP",
  "RAS",
  "ROM",
  "RPS",
  "RUS",
  "SEG",
  "SEX",
  "SJU",
  "SOS",
  "SPY",
  "SUG",
  "SUP",
  "SUR",
  "TBC",
  "TOA",
  "TOK",
  "TRE",
  "TYP",
  "UFO",
  "USA",
  "WAM",
  "WAR",
  "WWW",
  "XTC",
  "XTZ",
  "XXL",
  "XXX",
]
