import {
  validateRegistrationNumber,
  validateSocialSecurity,
  validateEmail,
  validateMobileNumber,
  validateOneTimePin,
} from "./fields"
import { fieldNames } from "../../../utils/enums"

export function fieldValidation(fieldName, fieldValue) {
  switch (fieldName) {
    case fieldNames.REG_NR:
      return validateRegistrationNumber(fieldValue)
    case fieldNames.SSN:
      return validateSocialSecurity(fieldValue)
    case "telephoneNumber":
      return validateMobileNumber(fieldValue)
    case "email":
      return validateEmail(fieldValue)
    case "oneTimePin":
      return validateOneTimePin(fieldValue)
    default:
      return {
        valid: false,
        message: "",
      }
  }
}
