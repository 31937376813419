import React, { useContext } from "react"
import styled from "@emotion/styled"
import { QuoteContext } from "../Context/QuoteContext"
import { cookieConsent } from "../Context/Actions"

export const CookieJar = () => {
  const { dispatch } = useContext(QuoteContext)

  const handleClick = () => {
    window.localStorage.setItem("accepted-cookies", true)

    dispatch(cookieConsent())
  }

  return (
    <CookieCard>
      <div>
        Vi använder{" "}
        <a href="https://support.evoli.se/sv/articles/3785093-om-cookies">
          cookies
        </a>
        . 🍪☕
      </div>
      <button type="button" onClick={handleClick}>
        OK
      </button>
    </CookieCard>
  )
}
const CookieCard = styled.div`
  background-color: #fcfcfc;
  padding: 12px 16px;
  border-radius: 10px;
  color: #71718e;
  font-size: 10px;
  box-shadow: 0 0 11px 2px rgba(40, 13, 165, 0.04);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: fit-content;
  /* margin-top: 37px; */
  margin-bottom: 30px;

  /* @media (min-width: 736px) {
    margin-top: 130px;
  } */

  @media (max-width: 896px) and (max-height: 600px) {
    margin-top: 60px;
  }

  a {
    text-decoration: underline;
    color: #71718e;
  }

  button {
    background: #e4e4ea;
    color: #71718e;
    font-size: 10px;
    line-height: 1.5;
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    padding: 5px 8px;
    margin-left: 10px;

    :hover {
      cursor: pointer;
    }
  }
`
