import React from "react"
import styled from "@emotion/styled"

export const LabelGroup = () => (
  <>
    <LabelContainer>
      <LabelRow>
        <LabelTag>Ändra körsträcka</LabelTag>
        <LabelTag>Byt till helförsäkring</LabelTag>
        <LabelTag>Djurkollisionstillägg</LabelTag>
      </LabelRow>
      <LabelRow>
        <LabelTag>Byt till halvförsäkring</LabelTag>
        <LabelTag>Byt betalsätt</LabelTag>
        <LabelTag>Byt till trafikförsäkring</LabelTag>
        <LabelTag>Hyrbilstillägg</LabelTag>
      </LabelRow>
      <LabelRow>
        <LabelTag>Avsluta försäkring</LabelTag>
        <LabelTag>Ta bort tillval</LabelTag>
        <LabelTag>Förare under 24</LabelTag>
      </LabelRow>
    </LabelContainer>
    <LabelContainerMobile>
      <LabelRow>
        <LabelMobile>
          Ändra
          <br />
          körsträcka
        </LabelMobile>
        <LabelMobile>
          Byt till hel-
          <br />
          försäkring
        </LabelMobile>
      </LabelRow>
      <LabelRow>
        <LabelMobile>
          Djurkollisions-
          <br />
          tillägg
        </LabelMobile>
        <LabelMobile>
          Byt till halv-
          <br />
          försäkring
        </LabelMobile>
        <LabelMobile>
          Byt
          <br />
          betalsätt
        </LabelMobile>
      </LabelRow>
      <LabelRow>
        <LabelMobile>
          Byt till trafik-
          <br />
          försäkring
        </LabelMobile>
        <LabelMobile>
          Avsluta
          <br />
          försäkring
        </LabelMobile>
        <LabelMobile>
          Ta bort
          <br />
          tillval
        </LabelMobile>
      </LabelRow>
      <LabelRow>
        <LabelMobile>
          Förare
          <br />
          under 24
        </LabelMobile>
        <LabelMobile>
          Hyrbils-
          <br />
          tillägg
        </LabelMobile>
      </LabelRow>
    </LabelContainerMobile>
  </>
)

const LabelContainerMobile = styled.div`
  margin-top: 40px;

  @media (max-width: 900px) {
    margin-bottom: 12px;
    margin-top: 20px;
  }

  @media (min-width: 667px) {
    display: none;
  }
`
const LabelContainer = styled.div`
  margin-top: 10px;

  @media (max-width: 666px) {
    display: none;
  }
`

const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 25px;

  @media (max-width: 900px) {
    margin-bottom: 12px;
  }
`
const LabelTag = styled.div`
  background: white;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.05);
  padding: 20px 33px;
  border-radius: 33.5px;
  color: #5558ea;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  min-width: 95px;

  :last-of-type {
    margin-right: 0px;
  }

  @media (max-width: 900px) {
    font-size: 12px;
    padding: 16px 24px;
    margin-right: 18px;
  }

  @media (max-width: 667px) {
    font-size: 11px;
    padding: 14px 22px;
    margin-right: 15px;
  }
`

const LabelMobile = styled.div`
  border-radius: 33.5px;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  color: #5558ea;
  font-weight: 600;
  line-height: 16px;
  font-size: 11px;
  text-align: center;
  padding: 12px 18px;

  margin-right: 12px;

  :last-of-type {
    margin-right: 0px;
  }

  @media (min-width: 414px) {
    font-size: 12px;
    padding: 16px 24px;
    margin-right: 15px;
  }
`
