import React from "react"
import styled from "@emotion/styled"
import {
  PreHeader,
  Header,
  Paragraph,
  Wrapper,
} from "../SectionContent/SectionContent"

export const NoCuringPeriodSection = () => (
  <Wrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="60"
      viewBox="0 0 52 61"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#5558EA"
          fillRule="nonzero"
          d="M39.661 0a6.102 6.102 0 1 0 0 12.203 6.102 6.102 0 0 0 0-12.203z"
        />
        <path
          stroke="#5558EA"
          strokeWidth="5"
          d="M8.771 18.305l11.822-6.102 9.91 2.93 3.056 2.257 6.865 10.068h11.44m-27.839 4.957l9.534 11.06-6.101 16.474m-8.631-20.035l-2.624 2.798H0"
        />
        <path
          fill="#5558EA"
          fillRule="nonzero"
          d="M24.407 24.407a6.102 6.102 0 1 0 0 12.203 6.102 6.102 0 0 0 0-12.203zm6.101-12.204a6.102 6.102 0 1 0 0 12.204 6.102 6.102 0 0 0 0-12.204z"
        />
        <path
          fill="#5558EA"
          fillRule="nonzero"
          d="M18.924 27.854l6.3-12.48 10.602 5.933-5.418 10.837 6.617 10.684-5.75 2.389-10.721-9.989z"
        />
      </g>
    </svg>

    <PreHeader>Inga bindningstider</PreHeader>
    <Header>Avsluta försäkringen när du vill</Header>
    <Paragraph>
      Hos oss kan du avsluta din försäkring precis när du vill. Vårt löfte till
      dig är att vi ska erbjuda bästa möjliga skydd och service, så att du ska
      vara nöjd och stanna kvar!
    </Paragraph>
  </Wrapper>
)

export const SafeWithUsSection = () => (
  <Wrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="60"
      viewBox="0 0 48 55"
    >
      <path
        fill="#5558EA"
        fillRule="nonzero"
        d="M36.667 15.714l-.001 5.238h5.239a5.237 5.237 0 0 1 5.233 5.022l.005.216v10.477a5.237 5.237 0 0 1-5.238 5.238l-5.239-.001v2.62c0 5.69-4.538 10.322-10.192 10.472L26.19 55H10.476C4.691 55 0 50.31 0 44.524v-28.81h36.667zm5.238 10.476h-5.239v10.476h5.239V26.19zM11.72 1.803v8.673H7.213V1.803h4.508zM20.566 0v10.476h-4.508V0h4.508zm8.845 5.238v5.238h-4.509V5.238h4.509z"
      />
    </svg>

    <PreHeader>Trygghet på riktigt</PreHeader>
    <Header>Du kan vara trygg hos oss</Header>
    <TextParagraph>
      Vi är licensierade av Finansinspektionen och samarbetar med några av
      världens största försäkringsbolag. Som kund hos oss kan du vara trygg med
      att vi garanterar alla utbetalningar.
    </TextParagraph>
  </Wrapper>
)

export const RecoSection = () => {
  return (
    <Wrapper>
      <svg
        width="48"
        height="44"
        viewBox="0 0 48 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.8 0C27.852 0 24 5.01144 24 5.01144C24 5.01144 20.148 0 13.2 0C5.9088 0 0 5.90343 0 13.188C0 23.1893 11.7888 32.8813 15.0744 35.9433C18.8592 39.4681 24 44 24 44C24 44 29.1408 39.4681 32.9256 35.9433C36.2112 32.8813 48 23.1893 48 13.188C48 5.90343 42.0912 0 34.8 0Z"
          fill="#5558EA"
        />
      </svg>
      <PreHeader>Våra kunder</PreHeader>
      <Header>Vad tycker våra kunder?</Header>
      <TextParagraph>
        Vi är både stolta och glada över att kunna visa upp vad våra kunder
        tycker om oss.
        <br />
        Om våra kunder är nöjda, så är vi nöjda! Läs vad våra kunder skriver om
        evoli!
      </TextParagraph>

      <Iframe
        src="https://widget.reco.se/v2/widget/4029117?mode=HORIZONTAL_QUOTE&border=false"
        width="100%"
        scrolling="no"
        data-reactroot=""
      ></Iframe>
    </Wrapper>
  )
}

const Iframe = styled.iframe`
  border: 0;
  display: block;
  padding: 0 60px;
  max-width: 1200px;
  height: 225px;

  @media (max-width: 768px) {
    margin-top: 20px;
    padding: 0 30px;
  }
  @media (max-width: 320px) {
    padding: 0 10px;
  }
`

export const InsuredCard = () => (
  <InsuredCardContainer>
    <Heading>Vi har försäkrat värden upp till:</Heading>
    <Amount>2,4 biljoner kronor</Amount>
    <SubAmount>(2 400 miljarder kr)</SubAmount>
    <Text>
      Denna siffra är ett teoretisk värde som beskriver vilket värde som måste
      betalas ut om alla försäkringstagare samtidigt vållar en skada där
      maxbeloppet av vad försäkringen täcker måste betalas ut. Detta är ett
      extremt osannolikt scenario som kanske inträffar en gång vart 90:e
      miljarder år.
    </Text>
  </InsuredCardContainer>
)

const TextParagraph = styled.p`
  color: #535375;
  max-width: 752px;
  color: #535375;
  font-size: 18px;
  text-align: center;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 40px;
  line-height: 1.6;

  @media (max-width: 896px) and (max-height: 600px) {
    font-size: 15px;
  }

  @media (max-width: 736px) {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #535375;
    max-width: 330px;
    margin-top: 8px;
    margin-bottom: 0;
  }
`

const Text = styled.p`
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #80809f;
  margin: 0;
  margin-top: 17px;
`

const SubAmount = styled.h6`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ff4984;
  margin: 0;
`

const Amount = styled.h5`
  font-size: 27px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #595bea;
  margin: 0;
  margin-top: 7px;
  margin-bottom: 3px;
`

const Heading = styled.h4`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #494965;
  margin: 0;
`

const InsuredCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  border-radius: 8px;
  box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;

  max-width: 641px;

  @media (max-width: 768px) {
    max-width: 330px;
  }

  margin: 0 auto;
  margin-top: 18px;
  margin-bottom: 60px;
  padding: 25px 20px;
`
