import React from "react"
import styled from "@emotion/styled"

export const PreHeader = props => <PreHeading {...props}></PreHeading>

const PreHeading = styled.h4`
  color: #ff4984;
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 10px;

  @media (max-width: 414px) {
    font-size: 12px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }
`

export const Header = props => <Heading {...props}></Heading>

const Heading = styled.h2`
  color: #292944;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  margin: 0;

  @media (max-width: 896px) and (max-height: 600px) {
    font-size: 28px;
  }
  @media (max-width: 736px) {
    font-size: 18px;
  }
  @media (max-width: 375px) {
    font-size: 18px;
  }
  @media (max-width: 320px) {
    font-size: 20px;
  }
`
export const Paragraph = props => <Text {...props}></Text>

const Text = styled.p`
  color: #535375;
  max-width: 718px;
  color: #535375;
  font-size: 18px;
  text-align: center;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 40px;
  line-height: 1.6;

  @media (max-width: 896px) and (max-height: 600px) {
    font-size: 15px;
  }

  @media (max-width: 736px) {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #535375;
    max-width: 330px;
    margin-top: 8px;
    margin-bottom: 0;
  }
`

export const Wrapper = props => <ContentWrapper {...props}></ContentWrapper>

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    svg {
      height: 40px;
      width: 35px;
    }
  }
`
