import React, { useContext } from "react"
import styled from "@emotion/styled"
import { QuoteContext } from "../../../../Context/QuoteContext"

const FieldLabel = props => {
  const { state } = useContext(QuoteContext)
  const { fields } = state

  const { valid, message: fieldMessage, dirty } = fields[props.labelFor]

  return (
    <Label
      title={props.descText}
      htmlFor={props.labelFor}
      valid={dirty && !valid}
      align={props.align}
    >
      {dirty & !valid ? <Circle /> : ""}
      {dirty & !valid ? fieldMessage : props.title}
    </Label>
  )
}
export default FieldLabel

const Circle = styled.div`
  width: 6px;
  height: 6px;
  background: #ff4984;
  border-radius: 100%;
  margin-right: 5px;
`

const Label = styled.label`
  display: block;
  width: 100%;
  border: 0;
  margin-bottom: 6px;
  font-size: 9px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: ${props =>
    props.valid ? "#ff4984" : props.theme.colors.PrimaryFieldLabel};

  display: flex;
  align-items: center;
  justify-content: ${props => props.align || "start"};
`
