export function validate(fieldValue) {
  if (!fieldValue) {
    return {
      valid: false,
      message: "Obligatoriskt",
    }
  }

  if (!/^[0-9]*$/.test(fieldValue)) {
    return {
      valid: false,
      message: "Enbart siffror är tillåtna",
    }
  }

  if (!/^[0-9]{4}$/.test(fieldValue)) {
    return {
      valid: false,
      message: "Fyll i fyra siffror",
    }
  }

  return {
    valid: true,
    message: "",
  }
}
