export function validate(fieldValue) {
  if (!fieldValue) {
    return {
      valid: false,
      message: "Obligatoriskt",
    }
  }

  // const regexNoZero = /^([+]460)/

  // if (regexNoZero.test(fieldValue)) {
  //   return {
  //     valid: false,
  //     message: "Får inte börja med nolla",
  //   }
  // }

  // const regexNoCountryCode = /^([+]46)/

  // if (!regexNoCountryCode.test(fieldValue)) {
  //   return {
  //     valid: false,
  //     message: "Vänligen skriv mobilnummer med +46",
  //   }
  // }

  // const regex = /^(([+]46)*(7)|07)[02369]\s*(\d{4})\s*(\d{3})$/

  const regex = /^(\+467[02369]\d{7}|07[0236]\d{7}|\+47\d{8,9}|\+358[45]\d{8}|\+45\d{8})$/

  // const regex = /^(\+467[02369]\d{7}|\+47\d{8,9}|\+358[45]\d{8}|\+45\d{8})$/

  // const regex = /^\s*(07[0236])\s*(\d{4})\s*(\d{3})$/

  var valid = regex.test(fieldValue)

  if (!valid) {
    return {
      valid: false,
      message: "Ogiltigt format",
    }
  }

  return {
    valid: true,
    message: "",
  }
}
