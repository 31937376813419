export function validate(fieldValue) {
  const regexFormat = /^(19|20)?[0-9]{2}[- ]?((0[0-9])|(10|11|12))[- ]?(([0-2][0-9])|(3[0-1])|(([7-8][0-9])|(6[1-9])|(9[0-1])))[- ]?[0-9]{4}$/

  if (!fieldValue) {
    return {
      valid: false,
      message: "Fyll i personnummer",
    }
  }

  if (!fieldValue.match(regexFormat)) {
    return {
      valid: false,
      message: "Felaktigt personnummer",
    }
  }

  if (!validateSocialSecurity(fieldValue)) {
    return {
      valid: false,
      message: "Felaktigt personnummer",
    }
  }

  return {
    valid: true,
    message: "",
  }
}

export const validateSocialSecurity = input => {
  input = input.replace("-", "")
  let sum = 0
  const parity = input.length % 2
  let digit

  if (input.length === 12 && ["19", "20"].includes(input.substring(0, 2))) {
    input = input.substring(2)
  }

  for (let i = 0; i < input.length; i = i + 1) {
    digit = parseInt(input.charAt(i), 0)
    if (i % 2 === parity) {
      digit *= 2
    }
    if (digit > 9) {
      digit -= 9
    }
    sum += digit
  }

  return 0 === sum % 10
}
