import React from "react"
import styled from "@emotion/styled"
import { FieldLabel } from "../fields"
import CalculateTextBox from "../CalculateTextBox/CalculateTextBox"
import { fieldNames } from "../../../../utils/enums"

const Email = () => {
  return (
    <FieldContainer>
      <FieldLabel
        labelFor={fieldNames.EMAIL}
        title="E-post"
        descText=""
        align="center"
      />

      <CalculateTextBox
        fieldName={fieldNames.EMAIL}
        placeHolder="Lägg till e-post"
        fieldType="email"
      />
    </FieldContainer>
  )
}
export default Email

const FieldContainer = styled.div`
  width: 100%;
  max-width: 206px;
`
