import React from "react"
import styled from "@emotion/styled"
import { LabelGroup } from "./LabelGroup"
import {
  PreHeader,
  Header,
  Paragraph,
  Wrapper,
} from "../SectionContent/SectionContent"

export const LabelSection = () => (
  <LabelSectionContainer>
    <Wrapper>
      <PreHeader>Frihet på riktigt</PreHeader>
      <Header>Snabbt, enkelt, flexibelt</Header>
      <Paragraph>
        Inga telefonköer eller blanketter, du kan ändra på vad som helst som har
        med din bilförsäkring att göra direkt i evoli-appen.
      </Paragraph>
    </Wrapper>
    <LabelGroup />
  </LabelSectionContainer>
)

const LabelSectionContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 160px 30px 0 30px;

  @media (max-width: 768px) {
    padding: 60px 22px 0 22px;
  }
`
