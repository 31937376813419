import React from "react"
import styled from "@emotion/styled"

const FieldMessage = props => {
  const { message } = props

  return <Paragraph>{message || <span>&nbsp;</span>}</Paragraph>
}
export default FieldMessage

const Paragraph = styled.p`
  color: ${props => props.theme.colors.CalculateTextBox.colorError};
  display: block;
  width: 100%;
  border: 0;
  margin: 0;
  margin-top: 2px;
  font-size: 0.6rem;
  font-weight: 500;

  @media (max-width: 960px) {
    font-size: 0.7rem;
    margin: 0;
  }
`
