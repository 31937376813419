import React from "react"
import styled from "@emotion/styled"

export const HeroTimeLine = () => (
  <TimeLineContainer>
    <TimeLine>
      <Step>
        <h4>15 sekunder</h4>
        <Circle>
          <svg
            className="step-1"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="20"
            viewBox="0 0 16 20"
          >
            <path
              fill="#5558EA"
              fillRule="nonzero"
              d="M13.454 0H1.922A1.927 1.927 0 0 0 0 1.933V17.4c0 1.068.86 1.933 1.922 1.933h11.532a1.927 1.927 0 0 0 1.922-1.933V1.933C15.376.865 14.516 0 13.454 0zm0 5.8H1.922V1.933h11.532V5.8zM4.805 9.667H2.883V7.733h1.922v1.934zm0 3.866H2.883V11.6h1.922v1.933zm0 3.867H2.883v-1.933h1.922V17.4zm7.688 0H6.727v-1.933h5.766V17.4zM8.65 13.533H6.727V11.6H8.65v1.933zm3.844 0h-1.922V11.6h1.922v1.933zm0-3.866h-1.922V7.733h1.922v1.934zm-3.844 0H6.727V7.733H8.65v1.934z"
            />
          </svg>
        </Circle>
        <Label>Se pris</Label>
      </Step>

      <Line></Line>

      <Step>
        <h4>30 sekunder</h4>
        <Circle>
          <svg
            className="step-2"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <g fill="#5558EA" fillRule="nonzero">
              <path d="M.433 17.808h19.325v2.147H.433v-2.147zM19.758 7.072H.433V4.388L10.095.093l9.663 4.295v2.684z" />
              <path d="M2.58 6.535h2.147v9.662H2.58V6.535zm4.294 0h2.148v9.662H6.874V6.535zm4.295 0h2.147v9.662h-2.147V6.535zm4.294 0h2.147v9.662h-2.147V6.535z" />
            </g>
          </svg>
        </Circle>
        <Label>Köp</Label>
      </Step>

      <Line></Line>

      <Step>
        <h4>Färdig</h4>
        <Circle>
          <svg
            className="step-3"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="21"
            viewBox="0 0 18 21"
          >
            <path
              fill="#5558EA"
              fillRule="nonzero"
              d="M8.947 0L.6 3.71v5.564c0 7.262 5.972 10.652 8.347 11.13 2.375-.478 8.346-3.868 8.346-11.13V3.71L8.947 0zm-.928 14.295l-3.88-3.881 1.31-1.311 2.57 2.57L13.391 6.3l1.311 1.311-6.683 6.683z"
            />
          </svg>
        </Circle>
        <Label>Skyddad</Label>
      </Step>
    </TimeLine>
  </TimeLineContainer>
)

const Line = styled.div`
  width: 42px;
  position: relative;
  z-index: 0;

  @media (max-width: 736px) {
    width: 17px;
  }

  :after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 42%;
    bottom: 0;
    left: -13px;
    border-top: 1px solid #9292d8;
    width: 67px;

    @media (max-width: 736px) {
      width: 47px;
      left: -15px;
    }
  }
`

const TimeLineContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 27px;

  @media (min-width: 736px) {
    margin-top: 52px;
  }
`

const TimeLine = styled.div`
  max-width: 400px;
  display: flex;
  justify-content: center;
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70px;

  h4 {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ff4984;
    margin: 0;
    margin-bottom: 7px;
    text-align: center;
  }
`

const Circle = styled.div`
  border: 1px solid #5558ea;
  border-radius: 100%;
  width: 46px;
  height: 46px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 736px) {
    width: 40px;
    height: 40px;

    .step-1 {
      width: 13px;
      height: 17px;
    }
    .step-2 {
      width: 17px;
      height: 17px;
    }
    .step-3 {
      width: 15px;
      height: 18px;
    }
  }
`

const Label = styled.div`
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5558ea;
  border-radius: 22px;
  background: #c4ffe1;
  padding: 7px 13px;
  margin-top: 10px;
  min-width: 62px;
  text-align: center;
`
