import React from "react"
import styled from "@emotion/styled"
import { useTheme } from "emotion-theming"
import { Section } from "../../Section"

const FullWidthTextSection = () => {
  const theme = useTheme()

  return (
    <Section bgColor={theme.colors.FullWidthContainer.background}>
      <FullWidthContainer>
        <Heading>Hur kan evoli vara billigare?</Heading>
        <Paragraph>
          evoli är det digitala alternativet för dig som sällan eller aldrig
          drabbas av skador och därför vill betala en så låg premie som möjligt.
          Genom att ha högre självrisker är övriga branschen och en helt digital
          process med en hög grad av självbetjäning är vår målsättning att kunna
          erbjuda riktigt bra priser till vår målgrupp.
        </Paragraph>
      </FullWidthContainer>
    </Section>
  )
}

export default FullWidthTextSection

const Paragraph = styled.p`
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 756px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.colors.FullWidthContainer.color};
`

const Heading = styled.h2`
  margin: 0;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${props => props.theme.colors.FullWidthContainer.headingColor};
`

const FullWidthContainer = styled.section`
  width: 100%;
  text-align: center;

  @media (max-width: 960px) {
    text-align: left;
  }
`
