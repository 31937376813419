import React from "react"
import styled from "@emotion/styled"
import { CardGroup } from "./CardGroup"
import { PreHeader, Header, Wrapper } from "../SectionContent/SectionContent"

export const CardSection = () => (
  <CardSectionContainer>
    <Wrapper>
      <PreHeader>Vi skyddar dig</PreHeader>
      <Header>Ett heltäckande skydd</Header>
      <Paragraph>
        Vår försäkring ger dig ett heltäckande skydd som du kan känna dig trygg
        med. Kortfattad information om utvalda skydd som ingår eller som du kan
        välja när du tecknar en försäkring.
      </Paragraph>
    </Wrapper>
    <CardGroup />
  </CardSectionContainer>
)

const CardSectionContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 160px 30px 0 30px;

  @media (max-width: 768px) {
    padding: 60px 22px 0 22px;
  }

  @media (max-width: 414px) {
    padding: 60px 15px 0 15px;
  }
`

const Paragraph = styled.p`
  color: #535375;
  font-size: 18px;
  text-align: center;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 40px;
  line-height: 1.6;
  max-width: 752px;

  @media (max-width: 896px) and (max-height: 600px) {
    font-size: 15px;
  }

  @media (max-width: 736px) {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #535375;
    max-width: 330px;
    margin-top: 8px;
    margin-bottom: 0;
  }
`
