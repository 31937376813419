import React from "react"
import styled from "@emotion/styled"
import { FieldLabel } from "../fields"
import CalculateTextBox from "../CalculateTextBox/CalculateTextBox"
import { fieldNames } from "../../../../utils/enums"

const SocialSecurity = () => {
  return (
    <FieldContainer>
      <FieldLabel
        labelFor={fieldNames.SSN}
        title="Personnummer"
        descText="Skriv i detta format..."
      />

      <CalculateTextBox
        fieldName={fieldNames.SSN}
        placeHolder="ååmmddnnnn"
        fieldType="tel"
        maxLength="20"
      />
    </FieldContainer>
  )
}
export default SocialSecurity

const FieldContainer = styled.div`
  width: 100%;
  max-width: 224px;

  @media (max-width: 414px) {
    margin-left: 10px;
  }

  @media (max-width: 375px) {
    margin-left: 5px;
  }

  @media (max-width: 374px) {
    margin-left: 0;
  }
`
