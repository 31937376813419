import React, { useContext } from "react"
import styled from "@emotion/styled"
import { QuoteContext } from "../../../../Context/QuoteContext"
import { fieldValidation } from "../FieldValidation"
import { updateField } from "../../../../Context/Actions"

const CalculateTextBox = props => {
  const { state, dispatch } = useContext(QuoteContext)

  const { fields } = state

  const { fieldName, placeHolder, fieldType } = props
  const { fieldValue, valid, dirty } = fields[fieldName]

  const onChangeHandler = e => {
    let value =
      fieldType !== "email"
        ? e.currentTarget.value.toUpperCase()
        : e.currentTarget.value
    const { message, valid } = fieldValidation(
      fieldName,
      value.trim().replace(/\s/g, "")
    )

    if (valid || e.type === "blur") {
      dispatch(
        updateField({
          name: fieldName,
          value: value,
          valid,
          message,
          dirty: true,
        })
      )
    } else {
      dispatch(
        updateField({
          name: fieldName,
          value: value,
        })
      )
    }
  }

  return (
    <>
      <FieldInput
        type={fieldType}
        placeholder={placeHolder}
        value={fieldValue}
        onChange={onChangeHandler}
        onBlur={onChangeHandler}
        valid={valid}
        dirty={dirty}
        autoComplete={props.autoComplete}
        id={props.id}
        maxLength={props.maxLength}
        minLength={props.minLength}
      />
    </>
  )
}
export default CalculateTextBox

const FieldInput = styled.input`
  width: 100%;
  background: ${props => props.theme.colors.CalculateTextBox.background};
  padding: 10px 15px 7px 15px;
  height: 55px;
  border: 0;
  letter-spacing: 1px;
  border-radius: 0.2rem;
  text-align: center;
  box-shadow: ${props => props.theme.colors.CalculateTextBox.boxShadow};
  -webkit-appearance: none;
  font-size: 19px;
  font-weight: 500;
  color: #6f72ff;
  border-bottom: 3px solid
    ${props => props.theme.colors.CalculateTextBox.colorDefault};

  ${props =>
    props.valid &&
    `color: ${props.theme.colors.CalculateTextBox.colorValid}; border-bottom: 3px solid ${props.theme.colors.CalculateTextBox.colorValid};`}

  ${props =>
    !props.valid &&
    props.dirty &&
    `color: ${props.theme.colors.CalculateTextBox.colorError}; border-bottom: 3px solid ${props.theme.colors.CalculateTextBox.colorError};`}


  ::placeholder {
    color: #6f72ff;
    opacity: 1;
  }

  focus: {
    outline: 0;
  }

  &[type="email"] {
    font-size: 14px;
  }

  @media (max-width: 960px) {
    /* max-width: 120px; */
    font-size: 16px;
  }

  @media (max-width: 375px) {
    margin-bottom: 20px;
  }
`
