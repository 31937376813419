export function validate(fieldValue) {
  if (!fieldValue) {
    return {
      valid: false,
      message: "Obligatoriskt",
    }
  }

  if (fieldValue.length > 254) {
    return {
      valid: false,
      message: "Ogiltigt",
    }
  }

  const regex = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

  var valid = regex.test(fieldValue)

  if (!valid) {
    return {
      valid: false,
      message: "Ogiltigt",
    }
  }

  // Further checking of some things regex can't handle
  var parts = fieldValue.split("@")

  if (parts[0].length > 64) {
    return {
      valid: false,
      message: "Ogiltigt",
    }
  }

  var domainParts = parts[1].split(".")

  if (
    domainParts.some(function(part) {
      return part.length > 63
    })
  ) {
    return {
      valid: false,
      message: "Ogiltigt",
    }
  }

  return {
    valid: true,
    message: "",
  }
}
