import React from "react"
import styled from "@emotion/styled"

export const Section = props => <SectionBlock {...props}></SectionBlock>

const SectionBlock = styled.section`
  background: ${props => (props.bgColor ? props.bgColor : "none")};
  width: 100%;
  padding: 40px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    margin: 0;
    text-align: left;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding: 50px 40px;
    margin: 0;
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
    ${props => props.fullViewHeight && "min-height: calc(100vh - 50px);"}
  }

  @media (max-width: 600px) {
    padding: 30px 20px;
    margin: 0;
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media (max-width: 375px) {
    padding: 30px 16px;
    margin: 0;
    text-align: left;
    flex-direction: column;
  }

  @media (max-width: 320px) {
    padding: 40px 15px;
    margin: 0;
    text-align: left;
    flex-direction: column;
  }
`
