import React from "react"
import styled from "@emotion/styled"

export const HeroParagraph = () => (
  <Text>
    Bäst pris för oss över 30 år, som haft körkort länge, sällan orsakar skador
    och kör en vanlig bil.
  </Text>
)

const Text = styled.h2`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-size: 21px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  color: ${props => props.theme.colors.HeroTextBox.color};
  max-width: 558px;
  margin-bottom: 36px;

  @media (max-width: 896px) and (max-height: 600px) {
    font-size: 16px;
  }

  @media (max-width: 414px) {
    font-size: 14px;
    margin-bottom: 14px;
    line-height: 1.57;
    max-width: 333px;
  }

  @media (max-width: 375px) {
    padding: 0 7px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
    margin-bottom: 15px;
  }
`
