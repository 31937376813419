import React from "react"
import styled from "@emotion/styled"

const FormMessage = props => {
  return <Paragraph>{props.message}</Paragraph>
}
export default FormMessage

const Paragraph = styled.p`
  color: #ff4984 !Important;
  margin: 0;
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
`
