import React from "react"
import Layout from "../components/Layout"
import "../styles/global.css"
import { HeroGetQuote } from "../components/PageComponents"
import SEO from "../components/seo"
import { CardSection } from "../components/PageComponents/IndexPage/CardSection/CardSection"
import { LabelSection } from "../components/PageComponents/IndexPage/LabelSection/LabelSection"
import {
  NoCuringPeriodSection,
  RecoSection,
  SafeWithUsSection,
} from "../components/PageComponents/IndexPage/DefaultSections/DefaultSections"
import styled from "@emotion/styled"
import Cookies from "js-cookie"
import { isNullOrWhitespace } from "../utils/HelperFunctions"

const IndexPage = ({ location }) => {
  const params = new URLSearchParams(location.search)

  // Get query params from affiliates
  const at_gd = params.get("at_gd")
  const sm_re = params.get("reqid")

  // Set cookies if params exists
  if (!isNullOrWhitespace(at_gd)) {
    Cookies.set(
      "at_gd",
      { created: new Date(), payload: at_gd },
      { expires: 30 }
    )
  }

  if (!isNullOrWhitespace(sm_re)) {
    Cookies.set(
      "sm_re",
      JSON.stringify({ created: new Date(), payload: sm_re }, null, 0),
      { expires: 30 }
    )
  }

  return (
    <Layout>
      <SEO />
      <HeroGetQuote />
      {/* <LabelSection /> */}
      <CardSection />
      <SubSections>
        <NoCuringPeriodSection />
        <SafeWithUsSection />
        <RecoSection />
      </SubSections>
    </Layout>
  )
}

export default IndexPage

const SubSections = styled.div`
  margin-top: 160px;
  margin-bottom: 160px;
  margin: 160px 0;

  @media (max-width: 768px) {
    margin: 60px 0;
  }

  div {
    margin-bottom: 60px;
  }
`
